import locale77427e78 from '../../lang/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"default","silentTranslationWarn":true,"numberFormats":{"default":{"currency":{"style":"currency","currency":"USD","currencyDisplay":"symbol"}},"ar":{"currency":{"style":"currency","currency":"USD","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"label":"English","code":"default","file":"en.js","iso":"en_US","defaultCurrency":"USD"},{"label":"Arabic","code":"ar","file":"ar.js","iso":"ar_AR","dir":"rtl","defaultCurrency":"USD"}],
  defaultLocale: "default",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/src/packages/theme/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://develop.almarwan.com",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "AE",
  autoChangeCookie: {"currency":false,"locale":true,"country":true},
  reloadOnLanguageChange: false,
  autoRedirectByLocale: false,
  normalizedLocales: [{"label":"English","code":"default","file":"en.js","iso":"en_US","defaultCurrency":"USD"},{"label":"Arabic","code":"ar","file":"ar.js","iso":"ar_AR","dir":"rtl","defaultCurrency":"USD"}],
  localeCodes: ["default","ar"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'ar.js': () => import('../../lang/ar.js' /* webpackChunkName: "lang-ar.js" */),
}
